<template>
  <div class="a-notifications__wrapper">
    <h2 class="a-notifications__title">Notifications</h2>
    <a-spinner
      v-if="inProgress"
      disable-transition
      class="a-notifications__spinner"
    />
    <template v-else-if="!isEmptyNotifications">
      <div
        v-for="section in sectionsWithContent"
        :key="section"
        class="a-notifications__section__wrapper"
      >
        <h3 class="a-notifications__section-title">
          {{ SECTION_NAME[section] }}
        </h3>
        <a-notification-section
          v-for="notification in notifications[section]"
          :key="notification.Id"
          :notification="notification"
          :section="section"
          class="a-notifications__message"
          v-on="$listeners"
        />
      </div>
    </template>

    <div v-else class="a-notifications__empty-content text-caption text-center">
      There are no notifications
    </div>
    <div
      v-if="!inProgress && !isEmptyNotifications"
      class="a-notifications__footer"
    >
      <a-link
        :to="linkToNotificationPage"
        class="a-notifications__see-all-link"
      >
        See all
      </a-link>
      <button class="a-notifications__button" @click.stop="$emit('clear-all')">
        Clear all
      </button>
    </div>
  </div>
</template>

<script>
import mixins from '@/utils/mixins'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { FM_ROUTE } from 'enums/routes'
import ANotificationSection, {
  NOTIFICATION_SECTION
} from './ANotificationSection'
import ASpinner from 'shared/ASpinner'

const NOTIFICATION_SECTIONS = Object.values(NOTIFICATION_SECTION)

export const SECTION_NAME = {
  [NOTIFICATION_SECTION.BUSINESS]: 'Business',
  [NOTIFICATION_SECTION.ANNOUNCEMENT]: 'Announcement',
  [NOTIFICATION_SECTION.NEWS]: 'News'
}

export default {
  name: 'ANotifications',
  mixins: [mixins.urlGenerators],
  components: { ASpinner, ANotificationSection },
  props: {
    notifications: propValidator([PROP_TYPES.OBJECT], false, () => ({})),
    inProgress: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  data() {
    return {
      SECTION_NAME,
      linkToNotificationPage: this.generateFmUrl(
        FM_ROUTE.PERSONAL_PROFILE_NOTIFICATIONS
      )
    }
  },
  computed: {
    sectionsWithContent() {
      return NOTIFICATION_SECTIONS.filter(
        section => this.notifications[section].length
      )
    },
    isEmptyNotifications() {
      return Object.values(this.notifications).every(
        section => section.length === 0
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.a-notifications__wrapper {
  background: #fff;
  font-family: $font-heading;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #000;

  .a-notifications__spinner {
    margin-top: 27px;
  }

  .a-notifications__section-title {
    margin-top: 20px;
    margin-bottom: 4px;
    font-family: $font-sans;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    color: $c--main;
  }

  .a-notifications__message {
    margin-top: 2px;
  }

  .a-notifications__empty-content {
    padding: 20px 0;
  }

  .a-notifications__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
  }

  .a-notifications__see-all-link {
    font-family: $font-sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  .a-notifications__button {
    height: 22px;
    font-family: $font-sans;
    font-size: 16px;
    font-weight: 400;

    &:hover {
      color: $c--main;
    }
  }
}
</style>
