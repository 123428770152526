<template>
  <div v-focusable class="a-user-icon__wrapper" @click="onClick">
    <button
      v-if="isUserWithFullNameWithoutAvatar"
      tabindex="-1"
      class="a-user-icon__avatar-without-image"
    >
      <span>{{ userInitials }}</span>
    </button>
    <a-image
      v-else
      :src="photo"
      :aspect-ratio="1"
      object-fit="cover"
      :alt="userFullName"
      class="a-user-icon__image"
    />
    <a-modal-widget
      v-model="isUserMenuVisible"
      :activator-selector="$options.consts.USER_ICON_SELECTOR"
      :right="-15"
      :top-offset="modalWidgetTopOffset"
      :show-social-networks="false"
      :scroll-to-top="false"
      :scroll-top="-57"
      class="a-user-icon__modal-widget"
      :with-tablet-layout="false"
    >
      <a-user-menu class="a-user-icon__user-menu" />
    </a-modal-widget>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import mixins from '@/utils/mixins'
import AModalWidget from '@/components/_modals/AModalWidget'
import AUserMenu from '../AUserMenu'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import {
  ACTIVATOR_ID,
  ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID
} from 'enums/activator-id'

const DEFAULT_AVATAR = 'default-avatar.svg'

export default {
  name: 'AUserIcon',
  mixins: [mixins.urlFormatters, hydrationHelpers],
  components: { AModalWidget, AUserMenu },
  consts: {
    USER_ICON_SELECTOR: ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID[ACTIVATOR_ID.AUTH]
  },
  data() {
    return {
      isUserMenuVisible: false
    }
  },
  computed: {
    ...mapGetters({
      userDetails: 'auth/userDetails',
      userFullName: 'auth/userFullName',
      userInitials: 'auth/userInitials'
    }),
    isUserWithFullNameWithoutAvatar() {
      return !this.userDetails.PhotoUrl && this.userInitials
    },
    photo() {
      return this.userDetails.PhotoUrl
        ? this.$helper.forceUrlFetch(this.userDetails.PhotoUrl)
        : this.$helper.getLinkToFlBucketFile(DEFAULT_AVATAR)
    },
    modalWidgetTopOffset() {
      return this.$_hydrationHelpers_mobileOrDesktopValue(0, 37)
    }
  },
  methods: {
    onClick() {
      this.isUserMenuVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.a-user-icon__wrapper {
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  background: $c--white;

  .a-user-icon__image {
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
  }

  .a-user-icon__avatar-without-image {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to bottom, #595959, #292929);
    font-size: 14px;
    text-transform: capitalize;
    width: 100%;
    height: 100%;
    color: $c--white;
    white-space: nowrap;
    border-radius: 50%;
    border: 1px solid $c--white;
    font-weight: 600;
    font-family: $font-sans;
    line-height: 10px;

    @include mobile {
      font-size: 10px;
    }

    & span {
      font-weight: normal;
      font-family: $font-sans;
      letter-spacing: 2px;
      padding-left: 2px;
    }
  }

  .a-user-icon__modal-widget {
    width: 250px;

    @include mobile {
      width: 100%;
      border: 0;
    }
  }

  .a-user-icon__user-menu {
    padding: 50px 34px 20px 24px;

    @include mobile {
      padding: 40px 0 20px 0;
    }
  }
}
</style>
