<template>
  <fade-transition :duration="150">
    <div
      v-if="value"
      ref="widgetContainer"
      class="modal-widget__absolute-wrapper"
      :class="{ tablet: withTabletLayout }"
      :style="wrapperStyle"
      v-on-clickaway="onClickAway"
      @mousedown="onMouseDown"
      @mouseup="onMouseUp"
      @blur.capture="onChildrenBlur"
    >
      <div class="modal-widget__relative-wrapper" :style="relativeWrapperStyle">
        <a-scrollable-content>
          <div>
            <div
              v-if="!isSlotComponentLoaded"
              class="modal-widget__slot-loading-spinner"
            >
              <a-wait-for-element-spinner
                v-if="slotSelector"
                v-model="isSlotLoadedInternal"
                :selector="slotSelector"
                :error-message="errorMessageOnSlotLoadFailure"
              />
            </div>
            <div v-show="isSlotComponentLoaded">
              <slot :closeModal="closeModalAndSetFocusToActivator" />
            </div>
          </div>
        </a-scrollable-content>
      </div>
      <div class="modal-widget__close-wrapper">
        <div
          v-focusable
          class="close-icon"
          @click.stop="closeModalAndSetFocusToActivator"
        />
      </div>
    </div>
  </fade-transition>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'
import { mapGetters } from 'vuex'

import AScrollableContent from 'shared/AScrollableContent'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { getFirstFocusableDescendant, hasParentWith } from '@fmpedia/helpers'
import AWaitForElementSpinner from 'shared/AWaitForElementSpinner/index.vue'
import { KEY_CODE } from '@fmpedia/enums'

const SCROLL_DURATION = 500
const MAX_SCROLL_DIFF = 1 // 1px

export default {
  name: 'AModalWidget',
  mixins: [hydrationHelpers],
  components: { AWaitForElementSpinner, AScrollableContent },
  props: {
    right: propValidator([PROP_TYPES.NUMBER, PROP_TYPES.STRING], false, 35),
    left: propValidator([PROP_TYPES.NUMBER, PROP_TYPES.STRING], false, null),
    topOffset: propValidator([PROP_TYPES.NUMBER, PROP_TYPES.STRING], false, 0),
    linksTop: propValidator([PROP_TYPES.NUMBER, PROP_TYPES.STRING], false, 130),
    value: propValidator([PROP_TYPES.BOOLEAN]),
    scrollToTop: propValidator([PROP_TYPES.BOOLEAN], false, true),
    scrollTop: propValidator([PROP_TYPES.NUMBER], false, 0),
    showSocialNetworks: propValidator([PROP_TYPES.BOOLEAN], false, true),
    withTabletLayout: propValidator([PROP_TYPES.BOOLEAN], false, true),
    slotSelector: propValidator([PROP_TYPES.STRING], false),
    errorMessageOnSlotLoadFailure: propValidator([PROP_TYPES.STRING], false),
    dataLoading: propValidator([PROP_TYPES.BOOLEAN], false, false),
    parentClassesToPreventClickaway: propValidator(
      [PROP_TYPES.ARRAY],
      false,
      () => []
    ),
    parentIdsToPreventClickaway: propValidator(
      [PROP_TYPES.ARRAY],
      false,
      () => []
    ),
    activatorSelector: propValidator([PROP_TYPES.STRING], false, '')
  },
  directives: { onClickaway },
  data() {
    return {
      isClickedInsideModalWindow: false,
      isSlotLoadedInternal: false
    }
  },
  computed: {
    ...mapGetters({
      isAppleBrowser: 'isAppleBrowser',
      isSafari: 'isSafari',
      isScrollbarHidden: 'isScrollbarHidden'
    }),
    isMobileOrTablet() {
      return this.withTabletLayout
        ? this.$_hydrationHelpers_isVisibleOnRange([
            this.$breakpoint.mobile,
            this.$breakpoint.tablet
          ])
        : this.$_hydrationHelpers_isLayoutMobile
    },
    desktopMaxHeight() {
      return this.$_hydrationHelpers_windowHeight + this.scrollTop
    },
    desktopMaxContentHeight() {
      const bordersHeight = 4

      return this.desktopMaxHeight - bordersHeight
    },
    wrapperStyle() {
      const top = 0

      return {
        'max-height': this.isMobileOrTablet
          ? 'auto'
          : `${this.desktopMaxHeight}px`,
        height: this.isMobileOrTablet ? `100vh` : 'auto',
        ...(this.left != null
          ? { left: this.left + 'px' }
          : { right: this.right + 'px' }),
        top: `${top + this.topOffset}px`
      }
    },
    relativeWrapperStyle() {
      const iosBottomPadding = this.isSafari ? 75 : 0
      const androidBottomPadding = 56
      const paddingBottom = `${
        this.isAppleBrowser ? iosBottomPadding : androidBottomPadding
      }px`

      return {
        'max-height': this.$_hydrationHelpers_isLayoutMobile
          ? '100%'
          : `${this.desktopMaxContentHeight}px`,
        ...(this.$_hydrationHelpers_isLayoutMobile &&
        this.$_hydrationHelpers_isLayoutPortrait
          ? { paddingBottom }
          : {})
      }
    },
    isSlotComponentLoaded() {
      return this.slotSelector ? this.isSlotLoadedInternal : true
    },
    setFocusDataTrigger() {
      return this.value && this.isSlotComponentLoaded && !this.dataLoading
    }
  },
  watch: {
    async setFocusDataTrigger(newVal) {
      if (!newVal) {
        return
      }

      /**
       * Waiting for nextTick was not enough, the focus was set to "body" in
       * some cases
       */
      setTimeout(() => {
        const focusableChild = getFirstFocusableDescendant(
          this.$refs.widgetContainer
        )

        if (focusableChild) {
          focusableChild.focus()
        }
      })
    },
    value: {
      async handler(val, oldVal) {
        if (!process.client) return

        await this.$nextTick()

        if (oldVal !== undefined || val) {
          this.handleModalScroll(val)
        }

        if (val && this.scrollToTop && !this.isMobileOrTablet) {
          this.$helper.watchAndExecuteOnce({
            ctx: this,
            immediate: true,
            field: 'isSlotLoadedInternal',
            conditionFn: newVal => !!newVal,
            handlerFn: this.handleScrollToTop
          })
        }
      },
      immediate: true
    },
    $_hydrationHelpers_windowWidth: {
      handler() {
        if (!this.value) return
        this.handleModalScroll(this.value)
      }
    },
    $route: {
      handler(newVal, oldVal) {
        if (!this.value) return

        const { isParentRoutePathChanged } = this.$helper.compareRoutes(
          oldVal,
          newVal
        )
        if (isParentRoutePathChanged) {
          this.closeModal()
        }
      }
    }
  },
  methods: {
    checkIfClickAwayShouldBePrevented(target) {
      return [
        ...this.parentClassesToPreventClickaway.map(parentClass =>
          hasParentWith(target, { className: parentClass })
        ),
        ...this.parentIdsToPreventClickaway.map(parentId =>
          hasParentWith(target, { id: parentId })
        )
      ].some(r => r)
    },
    onClickAway(event) {
      const isClickawayShouldBePrevented = this.checkIfClickAwayShouldBePrevented(
        event.target
      )

      if (isClickawayShouldBePrevented) return

      this.closeModal()
    },
    registerEscListener() {
      document.addEventListener('keydown', this.onDocumentKeyDown)
    },
    removeEscListener() {
      document.removeEventListener('keydown', this.onDocumentKeyDown)
    },
    onDocumentKeyDown(event) {
      if (!this.value || event.keyCode !== KEY_CODE.ESCAPE) {
        return
      }

      this.$emit('escape')

      this.closeModalAndSetFocusToActivator()
    },
    closeModalAndSetFocusToActivator() {
      this.closeModal()
      this.setFocusOnActivator()
    },
    closeModal(e) {
      if (
        e &&
        e.target &&
        (this.$helper.isElementCaptchaOverlay(e.target) ||
          hasParentWith(e.target, {
            attribute: 'do-not-close-modal-widget'
          }))
      ) {
        return
      }

      if (this.isClickedInsideModalWindow) {
        this.isClickedInsideModalWindow = false
        return
      }

      this.$emit('input', false)
    },
    handleModalScroll(isOpen) {
      const shouldHideScrollbar = isOpen && this.isMobileOrTablet
      if (shouldHideScrollbar && !this.isScrollbarHidden) {
        this.$_hydrationHelpers_hideScroll()
      } else if (!shouldHideScrollbar && this.isScrollbarHidden) {
        this.$_hydrationHelpers_showScroll()
      }
    },
    async handleScrollToTop() {
      const existingModal = document.querySelector(
        '.modal-widget__absolute-wrapper'
      )
      if (!existingModal) return

      const { top } = existingModal.getBoundingClientRect()

      /**
       * Determines if dynamic scrolling is required based on the difference between the top value
       * and the passed scrollTop prop value. The top value may not be an integer and can differ from
       * this.scrollTop. If the absolute rounded difference is 1px or less, dynamic scrolling is not
       * required, as it signifies minimal change in position.
       */
      const isScrollRequired =
        Math.abs(Math.round(top) - Math.abs(this.scrollTop)) > MAX_SCROLL_DIFF

      if (!isScrollRequired) {
        this.$emit('scroll-end')
        return
      }

      const options = {
        offset: this.scrollTop,
        onDone: () => {
          this.$emit('scroll-end')
        }
      }
      this.$scrollTo(
        '.modal-widget__absolute-wrapper',
        SCROLL_DURATION,
        options
      )
    },
    onMouseDown() {
      this.isClickedInsideModalWindow = true
    },
    onMouseUp() {
      this.isClickedInsideModalWindow = false
    },
    onChildrenBlur(event) {
      const { relatedTarget } = event

      if (
        !this.relatedTarget ||
        this.$helper.isRecaptchaChallenge(relatedTarget)
      ) {
        return
      }
      const isCloseOnBlurShouldBePrevented = this.checkIfClickAwayShouldBePrevented(
        relatedTarget
      )

      if (
        !hasParentWith(event.relatedTarget, {
          refEl: this.$refs.widgetContainer
        }) &&
        !isCloseOnBlurShouldBePrevented
      ) {
        this.closeModal()
      }
    },
    setFocusOnActivator() {
      if (!this.activatorSelector) return

      const activatorEl = document.querySelector(this.activatorSelector)

      if (!activatorEl || !activatorEl.focus) return

      activatorEl.focus()
    }
  },
  mounted() {
    this.registerEscListener()
  },
  beforeDestroy() {
    this.removeEscListener()
    this.handleModalScroll(false)
  }
}
</script>

<style lang="scss" scoped>
.modal-widget__absolute-wrapper {
  display: flex;
  width: 563px;
  background-color: white;
  border: 2px solid #69f;
  box-shadow: 0 4px 9px 2px rgba(115, 115, 115, 0.5);
  position: absolute;
  transform: translate3d(0, 0, 200px);
  cursor: default;
  z-index: $z-index-modal-widget;

  /deep/ .contact-us__heading {
    margin: 0 0 14px 0;
  }

  /deep/ .contact-us__lead {
    margin: 0 0 14px 0;
  }

  &.tablet {
    @include tablet {
      max-width: 768px;
      width: 100%;
      position: fixed;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);

      .modal-widget__relative-wrapper {
        height: 100%;
        width: 100%;
      }
    }

    @include mobile {
      left: 0;
      transform: translate(0, 0);
    }
  }

  @include mobile {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
}

.modal-widget__relative-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: hidden;

  @include mobile {
    height: 100%;
    width: 100%;
  }
}

.modal-widget__close-wrapper {
  position: absolute;
  width: 100%;
  min-height: 35px;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 10;

  .close-icon {
    top: 10px;
    right: 10px;

    &:hover {
      &::before,
      &::after {
        background: $c--main;
      }
    }
  }
}

.modal-widget__slot-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 35px 0;
  padding: 0 15px;
}

.modal-widget__slot-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 35px 0;
  padding: 0 15px;
}
</style>
