<template>
  <div class="header-actions__wrapper">
    <template v-if="!mobile">
      <a-lazy-hydrate when-visible>
        <a-button
          :button-style="BUTTON_STYLE.GREEN"
          :href="ROUTE.FL_DIR_HP"
          :open-in-new-tab="false"
          :trailing-slash="false"
          class="header-actions__brokers-button"
          >Brokers</a-button
        >
      </a-lazy-hydrate>
      <a-lazy-hydrate when-visible>
        <a-link :to="generateFmUrl(FM_ROUTE.HOMEPAGE)" open-in-new-tab>
          <img
            :src="$helper.getLinkToFlBucketFile('fm-logo.svg')"
            alt="Financial and Business News"
            title="Financial and Business News"
            class="header-actions__fm-logo"
          />
        </a-link>
      </a-lazy-hydrate>

      <a-lazy-hydrate when-visible>
        <div
          class="header-actions__element header-actions__one-signal one-signal-desktop-container"
          :data-ref="$options.consts.REFS.ONE_SIGNAL_DESKTOP"
        >
          <!-- Note: this icon is going to replace OneSignal's default Bell SVG -->
          <a-one-signal-icon
            :width="30"
            :height="30"
            :data-ref="$options.consts.REFS.ONE_SIGNAL_ICON_DESKTOP"
          />
        </div>
      </a-lazy-hydrate>
    </template>
    <div class="header-actions__element">
      <a-notification-icon
        :notification-count="newNotificationCount"
        class="header-actions__action-item"
      />
    </div>
    <div
      class="header-actions__element header-actions__element_sign-btn"
      :data-ref="$options.consts.REFS.LOGIN_ICON_WRAPPER"
    >
      <a-spinner
        v-if="isSpinnerVisible"
        :size="spinnerSize"
        class="header-actions__spinner"
      />
      <a-user-icon
        v-else-if="isLoggedIn"
        :id="$options.consts.ACTIVATOR_ID.AUTH"
        class="header-actions__avatar header-actions__action-item"
      />

      <button
        v-else
        :id="$options.consts.ACTIVATOR_ID.AUTH"
        :disabled="isPreviewMode"
        :class="{ disabled: isPreviewMode }"
        class="header-actions__action-item header-actions__sign-btn"
        @click="openAuthForm"
      >
        Sign in
      </button>
    </div>
  </div>
</template>

<script>
import { MODAL } from 'modals/AModalWrapper/index'
import { mapGetters, mapMutations } from 'vuex'
import AUserIcon from '@/components/AHeader/Shared/AUserIcon/index'
import ANotificationIcon from '@/components/AHeader/Shared/AHeaderActions/ANotificationIcon/index'
import { FM_ROUTE, ROUTE } from 'enums/routes'
import { BUTTON_STYLE } from 'shared/AButton/index'
import mixins from '@/utils/mixins'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import AOneSignalIcon from 'shared/AOneSignalIcon'
import { REFS } from 'enums/external-refs'
import ASpinner from 'shared/ASpinner'
import * as types from '@/store/mutation-types'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import {
  ACTIVATOR_ID,
  ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID
} from 'enums/activator-id'

const SPINNER_MOBILE_SIZE = 25
const SPINNER_DESKTOP_SIZE = 30

export default {
  name: 'AHeaderActions',
  mixins: [mixins.urlGenerators, hydrationHelpers],
  components: {
    AOneSignalIcon,
    ANotificationIcon,
    ASpinner,
    AUserIcon
  },
  props: {
    mobile: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  consts: { REFS, ACTIVATOR_ID },
  data() {
    return {
      ROUTE,
      FM_ROUTE,
      BUTTON_STYLE
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      isPreviewMode: 'isPreviewMode',
      isAuthFormLoadingInProgress: 'auth/isAuthFormLoadingInProgress',
      isAuthFormLoaded: 'auth/isAuthFormLoaded',
      isAuthStatusRequestInProgress: 'auth/isAuthStatusRequestInProgress',
      newNotificationCount: 'notifications/newNotificationCount'
    }),
    spinnerSize() {
      return this.$_hydrationHelpers_mobileOrDesktopValue(
        SPINNER_MOBILE_SIZE,
        SPINNER_DESKTOP_SIZE
      )
    },
    isSpinnerVisible() {
      return (
        this.isAuthFormLoadingInProgress || this.isAuthStatusRequestInProgress
      )
    }
  },
  methods: {
    ...mapMutations({
      setAuthFormLoadingInProgress: `auth/${types.SET_AUTH_FORM_LOADING_IN_PROGRESS}`
    }),
    openAuthForm() {
      if (!this.isAuthFormLoaded) {
        this.setAuthFormLoadingInProgress()
      }

      this.$helper.openModal(MODAL.AUTH_FORM, {
        activatorSelector: ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID[ACTIVATOR_ID.AUTH]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$icon-size-lg: 30px;
$icon-size-sm: 25px;

.header-actions__wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 30px;

  .header-actions__brokers-button {
    width: 90px;
    min-width: 90px;
    background: $c--upload-button;
    border-color: transparent;
    color: $c--white;
    margin-right: 30px;

    @include tablet {
      margin-right: 15px;
    }
  }

  .header-actions__fm-logo {
    width: 100px;
    min-width: 100px;
    height: 30px;
    margin-right: 30px;

    @include tablet {
      margin-right: 15px;
    }
  }

  .header-actions__element {
    margin-right: 12px;

    @include mobile {
      margin-right: 15px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .header-actions__one-signal {
    width: 30px;
    height: 30px;
  }

  .header-actions__element_sign-btn {
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-top: 2px;

    @include mobile {
      width: 25px;
      height: 25px;
    }

    .header-actions__spinner {
      position: absolute;
      top: 0;
      left: 0;
    }

    .header-actions__action-item {
      width: $icon-size-lg;
      height: $icon-size-lg;

      @include mobile {
        width: $icon-size-sm;
        height: $icon-size-sm;
      }
    }

    .header-actions__sign-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      white-space: nowrap;
      border: 1px solid white;
      border-radius: 50%;
      font-weight: 600;
      font-family: $font-sans;
      font-size: 7px;
      line-height: 9px;

      @include mobile {
        font-size: 6px;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}
</style>
