<template>
  <component
    v-if="notificationComponent"
    :is="notificationComponent"
    v-bind="notificationProps"
    :notificationDate="notificationDate"
    class="notification-message__wrapper"
    :class="dynamicClass"
    @delete="onDeleteNotification"
  />
</template>

<script>
import { pick } from 'ramda'

import { propValidator, PROP_TYPES } from '@/utils/validators'
import { SECTION_NAME } from '../index'

export const NOTIFICATION_SECTION = {
  BUSINESS: 'BusinessItems',
  ANNOUNCEMENT: 'AnnouncementItems',
  NEWS: 'NewsItems'
}

const SECTION_CLASSNAME = {
  [NOTIFICATION_SECTION.BUSINESS]: 'notification-message_business',
  [NOTIFICATION_SECTION.ANNOUNCEMENT]: 'notification-message_announcement',
  [NOTIFICATION_SECTION.NEWS]: 'notification-message_news'
}

const SECTION_COMPONENT = {
  [NOTIFICATION_SECTION.BUSINESS]: 'BusinessNotification',
  [NOTIFICATION_SECTION.ANNOUNCEMENT]: 'AnnouncementNotification',
  [NOTIFICATION_SECTION.NEWS]: 'NewsNotification'
}

const SECTION_FIELDS = {
  [NOTIFICATION_SECTION.BUSINESS]: ['CompanyName', 'FeatureName', 'Status'],
  [NOTIFICATION_SECTION.ANNOUNCEMENT]: ['Message'],
  [NOTIFICATION_SECTION.NEWS]: [
    'ArticleType',
    'Topic',
    'Slug',
    'CategorySlug',
    'SubCategorySlug',
    'CompanySlug',
    'IsMigrated'
  ]
}

export default {
  name: 'ANotificationSection',
  components: {
    NewsNotification: () => import('./NewsNotification'),
    AnnouncementNotification: () => import('./AnnouncementNotification'),
    BusinessNotification: () => import('./BusinessNotification')
  },
  props: {
    notification: propValidator([PROP_TYPES.OBJECT]),
    section: propValidator([PROP_TYPES.STRING], true, null, section =>
      Object.values(NOTIFICATION_SECTION).includes(section)
    )
  },
  computed: {
    notificationComponent() {
      return SECTION_COMPONENT[this.section]
    },
    notificationProps() {
      return pick(SECTION_FIELDS[this.section], this.notification)
    },
    dynamicClass() {
      return SECTION_CLASSNAME[this.section]
    },
    notificationDate() {
      return this.$helper.formatDate.toIntervalFromNow(
        this.notification.NotificationDate
      )
    }
  },
  methods: {
    onDeleteNotification() {
      const payload = {
        Id: this.notification.Id,
        Type: SECTION_NAME[this.section]
      }
      this.$emit('delete', payload)
    }
  }
}
</script>

<style lang="scss">
$business: 'notification-message_business' rgba(65, 192, 240, 0.2);
$announcement: 'notification-message_announcement' rgba(255, 239, 151, 0.5);
$news: 'notification-message_news' rgba(216, 216, 216, 0.5);

.notification-message__wrapper {
  display: block;
  position: relative;
  padding: 3px 38px 5px 17px;

  &--pointer {
    cursor: pointer;
  }

  $sections: $business $announcement $news;

  @each $section in $sections {
    &.#{nth($section, 1)} {
      background: nth($section, 2);
    }
  }

  .close-icon__wrapper {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 15px;
    height: 15px;
    content: '';
  }

  .notification-message__content,
  .notification-message__date {
    display: block;
    font-family: $font-sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .notification-message__date {
    color: rgba(#000, 0.4);
  }
}
</style>
