<template>
  <div class="a-user-menu__wrapper">
    <a-link
      v-for="menuItem in menuItems"
      :key="menuItem.url"
      class="a-user-menu__menu-item"
      :to="generateFmUrl(menuItem.url)"
      :open-in-new-tab="false"
    >
      {{ menuItem.name }}
    </a-link>

    <div class="a-user-menu__section-delimiter" />

    <a-link
      class="a-user-menu__menu-item"
      to="contact-us"
      :open-in-new-tab="false"
    >
      Contact us
    </a-link>

    <div class="a-user-menu__section-delimiter" />

    <button
      class="a-user-menu__menu-item a-user-menu__logout-button"
      @click="logout"
    >
      Log out
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import { FM_ROUTE, GUARDED_ROUTES } from 'enums/routes'
import mixins from '@/utils/mixins'

export default {
  name: 'AUserMenu',
  mixins: [mixins.urlGenerators],
  data() {
    return {
      menuItems: [
        {
          name: 'My Contributions',
          url: this.$helper.addPersonalAreaTabQuery(
            FM_ROUTE.PERSONAL_PROFILE_MY_CONTRIBUTIONS
          )
        },
        {
          name: 'My Profile',
          url: this.$helper.addPersonalAreaTabQuery(FM_ROUTE.PERSONAL_PROFILE)
        },
        {
          name: 'My Directory Listings',
          url: this.$helper.addPersonalAreaTabQuery(
            FM_ROUTE.PERSONAL_PROFILE_MY_COMPANIES
          )
        },
        {
          name: 'Announcements',
          url: FM_ROUTE.PERSONAL_PROFILE_ANNOUNCEMENTS
        },
        {
          name: 'Notifications',
          url: FM_ROUTE.PERSONAL_PROFILE_NOTIFICATIONS
        },
        {
          name: 'Saved for Later',
          url: this.$helper.addPersonalAreaTabQuery(
            FM_ROUTE.PERSONAL_PROFILE_SAVED_FOR_LATER
          )
        },
        {
          name: 'Followed Topics',
          url: FM_ROUTE.PERSONAL_PROFILE_FOLLOWED_TOPICS
        },
        {
          name: 'Followed Companies',
          url: FM_ROUTE.PERSONAL_PROFILE_FOLLOWED_COMPANIES
        },
        {
          name: 'Followed Authors',
          url: FM_ROUTE.PERSONAL_PROFILE_FOLLOWED_AUTHORS
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      deauthenticateUser: 'auth/deauthenticateUser'
    }),
    logout() {
      this.deauthenticateUser()
      if (GUARDED_ROUTES.includes(this.$route.name)) {
        this.$router.replace('/')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.a-user-menu__wrapper {
  color: #000;

  .a-user-menu__menu-item {
    display: block;
    margin: 20px 0;
    font-family: $font-sans;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: #4a4a4a;

    @include mobile {
      margin: 13px 34px 13px 37px;
    }
  }

  .a-user-menu__logout-button {
    height: 20px;
  }

  .a-user-menu__menu-item:hover {
    color: $c--main;
  }

  .a-user-menu__menu-item:first-child {
    margin-top: 0;
  }

  .a-user-menu__menu-item:last-child {
    margin-bottom: 0;
  }

  .a-user-menu__item-delimiter {
    display: none;

    @include mobile {
      display: block;
      height: 1px;
      background: $c--gray-secondary;
    }
  }

  .a-user-menu__section-delimiter {
    height: 2px;
    margin: 10px 0;
    background: rgba($c--gray-light, 0.5);
    content: '';

    @include mobile {
      background: $c--main;
    }
  }

  /* ToDo: remove this when all types of predefined button styles are ready */
  /deep/ .a-user-menu__menu-item button {
    text-align: left;
  }
}
</style>
