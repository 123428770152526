<template>
  <div class="a-notification-icon__wrapper">
    <a-lazy-hydrate when-visible>
      <a-icon
        :id="$options.consts.ACTIVATOR_ID.HEADER_NOTIFICATIONS"
        :icon="notificationBellIcon"
        :width="$_hydrationHelpers_mobileOrDesktopValue(25, 30)"
        :height="$_hydrationHelpers_mobileOrDesktopValue(25, 30)"
        fill="#fff"
        no-hover
        original
        is-button
        aria-label="Notifications"
        :disabled="!isLoggedIn"
        @click="onClick"
      />
    </a-lazy-hydrate>
    <a-lazy-hydrate v-if="isCounterVisible" when-visible>
      <div class="a-notification-icon__notification-area" @click="onClick">
        <span class="a-notification-icon__notification-count">{{
          notificationCountFormatted
        }}</span>
      </div>
    </a-lazy-hydrate>
    <a-lazy-hydrate when-visible>
      <a-modal-widget
        v-model="isNotificationWindowVisible"
        :activator-selector="$options.consts.HEADER_NOTIFICATIONS_SELECTOR"
        :slot-selector="$options.consts.NOTIFICATIONS_SELECTOR"
        :error-message-on-slot-load-failure="
          $options.consts.NOTIFICATIONS_ERROR_MESSAGE
        "
        :right="-15"
        :top-offset="modalWidgetTopOffset"
        :scroll-top="-56"
        :show-social-networks="false"
        :scroll-to-top="false"
        :data-loading="inProgress"
        class="a-notification-icon__notification-window"
      >
        <a-notifications
          :notifications="notifications"
          :in-progress="inProgress"
          class="a-notification-icon__notifications"
          @delete="onDeleteNotification"
          @clear-all="onClearAllNotifications"
        />
      </a-modal-widget>
    </a-lazy-hydrate>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import AIcon, { ICON } from 'shared/AIcon/index'
import ANotifications from '../ANotifications/index'
import AModalWidget from 'modals/AModalWidget/index'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import {
  ACTIVATOR_ID,
  ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID
} from 'enums/activator-id'

const MAX_NOTIFICATION_COUNT_TO_DISPLAY = 999

export default {
  name: 'ANotificationIcon',
  mixins: [hydrationHelpers],
  components: { AIcon, ANotifications, AModalWidget },
  props: {
    notificationCount: {
      type: Number,
      default: 0
    }
  },
  consts: {
    ACTIVATOR_ID,
    NOTIFICATIONS_ERROR_MESSAGE:
      'Error loading user notifications. Please try to reopen it.',
    HEADER_NOTIFICATIONS_SELECTOR:
      ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID[ACTIVATOR_ID.HEADER_NOTIFICATIONS],
    NOTIFICATIONS_SELECTOR: '.a-notifications__wrapper'
  },
  data() {
    return {
      ICON,
      isNotificationWindowVisible: false,
      notifications: {},
      inProgress: false
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn'
    }),
    notificationCountFormatted() {
      return this.notificationCount > MAX_NOTIFICATION_COUNT_TO_DISPLAY
        ? `${MAX_NOTIFICATION_COUNT_TO_DISPLAY}+`
        : this.notificationCount
    },
    isCounterVisible() {
      return this.isLoggedIn && this.notificationCount > 0
    },
    modalWidgetTopOffset() {
      return this.$_hydrationHelpers_isLayoutMobile ? 0 : 37
    },
    notificationBellIcon() {
      return this.isLoggedIn ? ICON.BELL_ACTIVE : ICON.BELL_INACTIVE
    }
  },
  methods: {
    ...mapActions({
      requestBellIconItems: 'notifications/requestBellIconItems',
      requestMarkReadBellIcon: 'notifications/requestMarkReadBellIcon',
      requestDeleteBellIconItem: 'notifications/requestDeleteBellIconItem',
      requestClearAllBellIcon: 'notifications/requestClearAllBellIcon'
    }),
    onClick() {
      if (this.isNotificationWindowVisible) return

      this.requestNotifications()
      this.requestMarkReadBellIcon()
      this.isNotificationWindowVisible = true
      this.$emit('open-notifications')
    },
    async requestNotifications() {
      try {
        this.inProgress = true
        this.notifications = await this.requestBellIconItems()
      } catch (err) {
        this.$errorHandler(err, this)
      } finally {
        this.inProgress = false
      }
    },
    async onDeleteNotification(payload) {
      try {
        this.inProgress = true
        await this.requestDeleteBellIconItem(payload)
        await this.requestNotifications()
      } catch (err) {
        this.$errorHandler(err, this)
      } finally {
        this.inProgress = false
      }
    },
    async onClearAllNotifications() {
      try {
        this.inProgress = true
        await this.requestClearAllBellIcon()
        await this.requestNotifications()
      } catch (err) {
        this.$errorHandler(err, this)
      } finally {
        this.inProgress = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.a-notification-icon__wrapper {
  position: relative;
  cursor: pointer;
  border-radius: 50%;

  /deep/ .a-icon {
    &.a-icon_disabled.a-icon_is-button {
      opacity: 1;
    }
  }

  .a-notification-icon__notification-area {
    position: absolute;
    top: -10px;
    right: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 2px;
    padding-right: 2px;
    min-width: 22px;
    height: 22px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background: $c--notification-count;

    @include mobile {
      top: -10px;
      right: -12px;
    }
  }

  .a-notification-icon__notification-count {
    font-family: $font-sans;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    color: #000;
  }

  .a-notification-icon__notification-window {
    width: 604px;

    @include mobile {
      width: 100%;
      border: none;
    }
  }

  .a-notification-icon__notifications {
    padding: 39px 16px 11px;
  }
}
</style>
