<template>
  <div v-if="isIconVisible">
    <a-icon
      :icon="currentIcon"
      :width="width"
      :height="height"
      no-hover
      original
    />
  </div>
</template>

<script>
import AIcon, { ICON } from 'shared/AIcon'
import { SHOW_ONE_SIGNAL_ICON_BUS_NAME } from 'enums/oneSignal'
import { propValidator, PROP_TYPES } from '@/utils/validators'

export default {
  name: 'AOneSignalIcon',
  components: { AIcon },
  props: {
    width: propValidator([PROP_TYPES.NUMBER]),
    height: propValidator([PROP_TYPES.NUMBER])
  },
  data() {
    return {
      currentIcon: ICON.ONE_SIGNAL_INACTIVE,
      isIconVisible: false
    }
  },
  methods: {
    showOneSignalIcon(isSubscribed) {
      this.currentIcon = isSubscribed
        ? ICON.ONE_SIGNAL_INACTIVE
        : ICON.ONE_SIGNAL_ACTIVE

      this.isIconVisible = true
    },
    garbageCollectIcon() {
      const parent = this.$el.parentElement

      if (!parent) return

      const elementToRemove = this.$helper.getElByDataRef(
        this.$attrs['data-ref'],
        parent
      )

      if (!elementToRemove) return

      this.$helper.removeNode(elementToRemove)
    }
  },
  mounted() {
    this.$bus.$on(SHOW_ONE_SIGNAL_ICON_BUS_NAME, this.showOneSignalIcon)
  },
  beforeDestroy() {
    this.$bus.$off(SHOW_ONE_SIGNAL_ICON_BUS_NAME, this.showOneSignalIcon)
    this.garbageCollectIcon()
  }
}
</script>

<style lang="scss" scoped></style>
